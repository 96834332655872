
import { Component, Vue } from 'vue-property-decorator'
import Carousel from './Carousel.vue'
import { ResultList } from '../../types/insectMonitor.d'

@Component({
  components: {
    Carousel
  }
})
export default class DevDetail extends Vue {
  private deviceData = {}
  private imgList = []
  private imgData: ResultList[] = []
  private deviceStatus = ''
  private historyBtnShow = true

  get deviceId () {
    return this.$route.params.deviceId as string
  }

  created () {
    this.getDetail()
    this.getData()
    this.getImgData()
  }

  getDetail () {
    this.$axios.get(this.$apis.insectMonitor.selectInsectConfigByList, {
      deviceId: this.deviceId
    }).then(res => {
      if (res.total > 0) {
        this.historyBtnShow = !!res.list.find((item: any) => {
          return item.typeCode === '1' && item.type === '2'
        })
      }
    })
  }

  getData () {
    this.$axios.get(this.$apis.insectMonitor.selectInsectInfo, {
      deviceId: this.deviceId
    }).then((res) => {
      this.deviceData = res
    })
  }

  // 获取虫害轮播图
  getImgData () {
    this.$axios.get(this.$apis.insectMonitor.selectInsectImgResultByList, {
      deviceId: this.deviceId,
      dateNum: '2'
    }).then((res) => {
      this.imgList = res
    })
  }

  // 智库推荐
  goRecommend (insectName: string) {
    this.$router.push({
      name: 'insectMonitorRecommendDev',
      params: { insectName }
    })
  }

  imgDataChange (list: ResultList[]) {
    this.imgData = list
  }

  toMonitor () {
    this.$router.push({ name: 'detailHistoryMonitor', params: { projectId: this.$route.params.projectId, deviceId: this.deviceId } })
  }

  toData () {
    this.$router.push({ name: 'detailHistoryData', params: { projectId: this.$route.params.projectId, deviceId: this.deviceId } })
  }
}
